import React, { useState } from 'react';
import './Chat.css'; // Importamos los estilos
import axios from 'axios';

const Chat = () => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [isExpanded, setIsExpanded] = useState(true);  // Estado para controlar expansión

  // Función para reproducir un sonido
  const playSound = (soundFile) => {
    const audio = new Audio(`/sounds/${soundFile}`);
    audio.play();
  };

  const handleSendMessage = async () => {
    if (!message) return;

    // Agregar mensaje del usuario al historial
    setMessages([...messages, { role: 'user', content: message }]);
    setMessage('');
    
    // Reproducir el sonido cuando el usuario envía un mensaje
    playSound('send-sound.mp3');

    try {
        // Enviar solicitud al backend de Flask
        const response = await axios.post('/api/chat', {
            message: message,
        });

        const botMessage = response.data.response;

        // Agregar respuesta del bot al historial
        setMessages([...messages, { role: 'user', content: message }, { role: 'bot', content: botMessage }]);

        // Reproducir el sonido cuando el bot responde
        playSound('receive-sound.mp3');
    } catch (error) {
        console.error('Error al comunicarse con el servidor:', error);
    }
  };

  const toggleChat = () => {
    setIsExpanded(prevState => !prevState);  // Cambiar estado de expansión
  };

  return (
    <div className="chat-container">
      <div className="chat-header" onClick={toggleChat} style={{ cursor: 'pointer' }}>
        {isExpanded ? 'Chat' : 'Chat'}
      </div>
      {isExpanded && (
        <div className="chat-messages">
          {messages.map((msg, index) => (
            <div key={index}>
              <strong>{msg.role === 'user' ? 'Tú: ' : 'Bot: '}</strong>{msg.content}
            </div>
          ))}
        </div>
      )}
      {isExpanded && (
        <div className="chat-input-container">
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Escribe tu mensaje..."
          />
          <button onClick={handleSendMessage}>Enviar</button>
        </div>
      )}
    </div>
  );
};

export default Chat;
